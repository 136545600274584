.header {
    margin-bottom: 5vh;
    font-family: 'Work Sans', sans-serif;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    background-color: #282c34;
    color: white;
    border: 0;
}

.react-datepicker__input-container input {
    background-color: #333842;
    border: 5px solid #333842;
    border-radius: 1px;
    text-align: center;
}